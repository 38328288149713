.portfolio {
    overflow-x: hidden;
    background: linear-gradient(to right, rgba(0, 98, 185, 0.8), rgba(0, 98, 185, 0.81)), url(../common-bg.svg);
    background-position: center;
    background-size: cover;
}

    .portfolio h1 {
        color: white;
        margin-bottom: 40px;
        font-size: 2rem; /* Added for better heading size */
    }


#projects {
    padding-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.project {
    color: white;
    margin-bottom: 20px;
    width: calc(100%);
    border-radius: 5px;
    overflow: hidden;
    align-content: center;
}

@media (min-width: 768px) {
    .project {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 10rem !important;
    }
}

@media (min-width: 768px) {
    .project-info {
        padding-left: 4rem !important;
        height: 434px;
        position: relative;
    }
}

@media (min-width: 768px) {
    .projbutton {
        text-align:end;
    }
}

.project img {
    padding-right: 0;
    width:80%;
    object-fit: cover;
}

@media (min-width: 768px) {
    .container {
        position: relative;
    }
}

.project-info {
    
    height: 100%;
    display:grid;
    align-content: center;
}
.project h4{
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: left;
}
.project h2 {
    margin-bottom: 10px;
    font-size: 2rem; /* Adjusted heading size */
    text-align: left;
}

.project h3 {
    font-size: 1rem; /* Adjusted heading size */
    text-align: left;
}

.projects-title {
    color: white;
    margin-bottom: 3rem;
    font-size: 8rem; /* Adjusted heading size */
    text-align: center;
}

.project p {
    margin-top: 24px;
    margin-bottom: 24px;
    margin-bottom: 20px;
    text-align: left;
}

.project a {
    color: #fff; /* Set text color to white for better contrast */
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}



/* Improved section styling */
.work-experience,
.skills,
.education {
    margin: 20px 0;
    padding: 10px;
    font: bold;
    border-radius: 5px; /* Added rounded corners for visual polish */
}

@media (min-width: 768px) {
    .skills {
        padding-left: 3rem;
    }
}

.work-experience h2,
.skills h2,
.education h2 {
    font-size: 1.2rem;
    font-weight: bold;
}

.work-experience ul,
.skills ul {
    list-style: none;
    padding: 0;
}

.work-experience li,
.skills li {
    margin-bottom: 5px;
}

.imageAboutPage {
    width: 100%;
    border-radius: 1rem;
}

.skills-container {
    text-align: center; /* Center the heading and grid */
}

.skills {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust width as needed */
    grid-gap: 10px;
}

.skills1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust width as needed */
    grid-gap: 10px;
    text-align: center;
}



@media (max-width: 767px) {
    .skills__skill img {
        width: 20px;
        height: 20px;
        margin-right: 20px !important;
    }
    .project img{
        width:100%;
    }
    .buttonforproj{
        padding-top:4rem;
    }

    .skills__skill1 img {
        width: 20px;
        height: 20px;
        margin-right: 20px !important;
    }
}

.skills__skill img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}
.skills__skill1 img {
    width: 20px;
    height: 20px;
    margin-right:12px;
}
.skills__skill {

    padding: 1rem 1rem;
    margin-bottom: 10px;
    background-color: rgba(57, 106, 151, 0.8);
    border-radius: 5px;
    font-weight: 400;
}

.skills__skill1 {
    padding: 1rem 1rem;
    margin-bottom: 10px;
    background-color: rgba(57, 106, 151, 0.8);
    border-radius: 5px;
    font-weight: 400;
}

@media (min-width: 768px) {

    .aboutmeparagraph {
        padding-left: 3rem !important;
    }
}

.aboutmeparagraph {
    padding-left: 1rem;
    font-size: 1.7rem;
}

.skills-container {
    padding-top: 22px;
}

.sec-pad {
    padding: 8rem 0;
}

@media only screen and (max-width: 56.25em) {
    .sec-pad {
        padding: 6rem 0;
    }
}

.sec-pad-top {
    padding-top: 0;
}

@media (min-width: 768px) {
    .sec-pad {

        padding:0 !important;
        padding-top: 8rem !important;
    }
    .sec-pad-bot{
        padding-bottom:8rem !important;
    }
}

.proj-cen {
    display:flex;
}

.bgimage-proj {
    color: #fff;
}

.bgimage-about {
    color: #fff;
}



.projbutton {
    background-color: white;
    color: black !important; /* Black text */
    padding: 10px 20px; /* Adjust padding as needed */
    border: none; /* Remove default border */
    border-radius: 0.75rem; /* Add rounded corners */
    cursor: pointer; /* Change cursor to indicate interactivity */
    text-decoration: none; /* Remove underline */
    margin-right: 32px;
    margin-top: 2rem;
}

.tooltitle {
    padding-bottom: 1rem;
}

.contactalign {
    width: 100%;
    display: flex;
    justify-items: center;
    justify-content: center;
}

@media (min-width: 768px) {
    .rem h2 {
        display: none;
    }

    .grid.flex > div:nth-child(2) .project .overstyle img {
        padding-right: 0;
        width: 80%;
        height: 100%;
        max-height: 710px;
        object-fit: contain;
        object-position: top;
    }

    .buttonforproj {

        padding-top: 4rem;
        }
}
.contact {
    background: linear-gradient(to right, rgba(0, 98, 185, 0.8), rgba(0, 98, 185, 0.8)), url(../common-bg.svg);
    background-size: cover;
    background-position: center;
}

.cont {
}

.cen {
    width: 100%;
    justify-content: center;
    justify-items: center;
    padding-left: 20%;
    padding-right: 20%;
}

.overstyle {
    padding-right: 0;
    padding-left: 0 !important;
}
@media (max-width: 767px) {

    .aboutmeparagraph {
    text-align:center;
    padding-top:4rem;
        
    }
    .project-info{
        padding:0;
        padding-top:1.5rem;
        padding-bottom:4rem;

    }
    .rem h2 {
        display: none;
    }
        .cen{
            padding:0;
        }



}

