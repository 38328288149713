#navbar {
    color: white !important;
    background-color: #333;
    padding: 1.5rem 0;
    padding-right: 0.5rem;
    font-size: large;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.3s ease-in-out;
    z-index: 100;
}

.navbar-brand {
    color: white !important;
    text-decoration: none;
    margin-left: 3rem;
}

.navbar-toggler-icon {
    color: white !important;
    
    border: none;
}
.navbar-toggler {
    background-color: white !important;
}


.nav-link {
    color: white !important;
    text-decoration: none;
    margin-left: 3rem;
}


@media (max-width: 768px) {
    .navbar-nav {
        display: none;
    }

        .navbar-nav.show {
            display: block;
        }

    .nav-link {
        color: white;
        margin-left: 1rem;
        font-size: small;
    }

    .navbar-brand {
        margin-left: 1rem;
    }

    .navbar-toggler:focus {
        box-shadow: none !important;
    }
}
html {
    scroll-behavior: smooth !important;
}