.SectionTitle { /* Add a base class for easier styling */
    color: white; /* Text color */
    /* Font family */
    padding-bottom: 4rem; /* Padding */
    user-select: none; /* Disable text selection */
}

    .SectionTitle span.subtitle { /* Subtitle styles */
        opacity: 0.5; /* Opacity */
        text-transform: uppercase; /* Text transformation */
    }

    .SectionTitle h2 { /* Title styles */

        font-size: 2.5rem; /* Base font size */

        @media (min-width: 640px) { /* Sm:text-8xl (adjust as needed) */
            font-size: 3rem;
        }

        @media (min-width: 768px) { /* Md:text-9xl (adjust as needed) */
            font-size: 3.5rem;
        }

        letter-spacing: 0.2em; /* Tracking */
    }

        .SectionTitle h2 .bounce { /* Animation class for individual characters */
            animation: bounce 0.6s ease-in-out infinite alternate;
        }

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-3px);
    }

    100% {
        transform: translateY(0);
    }
}
