.contact-form {
    /* Styles for the main container */

    width: 100%;
    height: 96%;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    background-color: white;
}



.message {
    font-weight: 400;
    font-size: 3rem;
    color: white;
    padding-left: 20%;
    border-radius: 1rem 1rem 0rem 0; /* Top-left, Top-right, Bottom-right, Bottom-left */
    text-align: left;
    /* Box shadow */
}


.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}




.form {
    /* Styles for the form element */
    width: 100%; /* Specify a value for width */
    height: auto; /* Use 'auto' or a specific value */
    display: flex; /* Enable flexbox layout */
    flex-direction: column; /* Stack divs vertically */
    align-items: center; /* Horizontally center divs */
    padding: 4rem;
    color: gray;
}

.name-field,
.email-field,
.message-field {
    /* Styles for individual form fields */
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem; /* Adjust as needed */
    width: 90%;
    border-radius: 5px;
    font: bold;
}

.message-field {
    margin-bottom: 0 !important;
}

@media (min-width: 768px) {
    .submit-button {
        width: 127px;
        height: 44px;
    }
}

.input-field {
    margin-top: 1rem;
    padding: 0.5em;
    border-radius: 0.55rem; /* Use border-radius for rounded corners */
    border: 1px solid #ccc; /* Adjust as needed */
}

.submit-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: 4rem;
    padding-right: 2rem;
}

.submit-button {
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 1rem;
    border: none;
    background-color: rgba(0, 98, 185, 0.81);
}





.contactinfo1 img {
    padding-left: 2rem;
    /* Add space to the right of the image */
    width: 4rem;
}

@media (max-width: 767px) {
    .form {
        padding: 0.5rem;
        padding-top: 1rem;
    }
}

.contact__form-container {
    background: white;
    padding: 4rem;
    margin-top: 6rem;
    max-width: 80rem;
    text-align: right;
    width: 95%;
    border-radius: 5px;
    margin: 5rem auto 0 auto;
}
