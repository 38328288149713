footer {
    background-color: #333;
    color: #fff;
    bottom: 0;
}


.home-hero__social-foot {
    width: 10rem;
    display: flex;
    padding-right: 1rem;
}

.myname {
    grid-column: 2;
    color: white;
    justify-self: center; /* Horizontal centering */
    align-self: center;
}

.main-footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Define 3 rows with equal size */
}

.contactinfo {
    grid-column: 3;
    display: flex;
    justify-self: right; /* Horizontal centering */

    align-items: center;
    color: white;
    z-index: 2; /* Set a higher z-index value */
    padding-right: 1rem;
}

.myemail {
    color: white;
}

.home-hero__info {
    margin: 3rem auto 0 auto;
    max-width: 80rem;
}

.home-hero__cta {
    margin-top: 3rem;
    display: flex;
    justify-content: center; 
    text-align: center;
}
@media (max-width: 767px) {
    .contactinfo {
        font-size: smaller;
        grid-column: 2;
        grid-column: span 2;
        padding-right: 0.75rem;
    }
    .main-footer__row {
    padding-right:0;
    padding-left:0;
    }
    .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
    .home-hero__social-foot{
        padding-right:0;
    }
}