.bgimage {
    color: #fff;
    background: linear-gradient(to right, rgba(0, 98, 185, 0.8), rgba(0, 98, 185, 0.81)), url(../common-bg.svg);
    background-position: center;
    height: 100vh;
}

@media (min-width: 992px) { /* Target screens larger than 992px */
    .bgimage-proj {
        /* No need to adjust height here */
    }
}

.home-hero__mouse-scroll-cont {
    position: absolute;
    cursor: pointer;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 37.5em) {
    .home-hero__mouse-scroll-cont {
        display: none;
    }
}


/* text css above hero image*/
.hero_title {
    font-size: 4.5rem;
    margin-bottom: 2rem;
}

.hero_desc {
    font-size: 2rem;
}

.hero-text {
    
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.nav-link.active, .nav-link:hover {
    color: white; /* Replace with your color */
}

.home-hero__cta a {
    background-color: #fff; /* White background */
    color: #000; /* Black text */
    padding: 10px 20px; /* Adjust padding as needed */
    border: none; /* Remove default border */
    border-radius: 5px; /* Add rounded corners */
    cursor: pointer; /* Change cursor to indicate interactivity */
    text-decoration: none; /* Remove underline */
    display: inline-block; /* Allow setting width and height */
    width: 200px;
    height: 50px;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bryhero {
    width: 100%;
    justify-content: center;
    justify-items: center;
    padding-left: 20%;
    padding-right: 20%;
}

.btn {
    background: #fff;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: inline-block;
    font-weight: 700;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
    transition: transform .3s;
}

    .btn:hover {
        transform: translateY(-3px);
    }

.btn--bg {
    padding: 1.5rem 8rem;
    font-size: 2rem;
}

.btn--med {
    padding: 1.5rem 5rem;
    font-size: 1.6rem;
}

.btn--theme {
    background: #0062b9;
    color: #fff;
}

.btn--theme-inv {
    color: #0062b9;
    background: #fff;
    border: 2px solid #0062b9;
    box-shadow: none;
    padding: calc(1.5rem - 2px) calc(5rem - 2px);
}

.home-hero__socials {
    margin-right: 2.5rem;
    position: absolute;
    top: 50%;
    border: 2px solid #eee;
    border-left: 2px solid #eee;
    transform: translateY(-50%);
}

@media only screen and (max-width: 56.25em) {
    .home-hero__socials {
        display: none;
    }
}

.home-hero__mouse-scroll-cont {
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
}

@media only screen and (max-width: 37.5em) {
    .home-hero__mouse-scroll-cont {
        display: none;
    }
}

.home-hero__social {
    width: 5rem;
}

.home-hero__social-icon-link {
    width: 100%;
    display: block;
    padding: 1.2rem;
    border-bottom: 2px solid #eee;
    transition: background .3s;
}

    .home-hero__social-icon-link:hover {
        background: rgba(255, 255, 255, 0.1);
    }

.home-hero__social-icon-link--bd-none {
    border-bottom: 0;
}

.home-hero__social-icon {
    width: 100%;
}

.home-hero__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 90rem;
    width: 92%;
}

@media only screen and (max-width: 37.5em) {
    .home-hero__content {
        padding: 19rem 0 13rem 0;
        margin: auto;
        position: static;
        transform: translate(0, 0);
    }
}

.home-hero__info {
    margin: 3rem auto 0 auto;
    max-width: 80rem;
}

.home-hero__cta {
    display: flex;
    justify-content: center; /* Corrected missing semicolon */
    text-align: center;
}

@media only screen and (max-width: 37.5em) {
    .home-hero {
        height: unset;
        min-height: unset;
    }
}

.mouse {
    width: 25px;
    height: 40px;
    border: 2px solid #eee;
    border-radius: 60px;
    position: relative;
    overflow: hidden;
}
@media (min-width: 768px) {
    .hero-text {
    }
}
    .mouse::before {
        content: '';
        width: 5px;
        height: 5px;
        position: absolute;
        top: 7px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #eee;
        border-radius: 50%;
        opacity: 1;
        animation: wheel 1.3s infinite;
        -webkit-animation: wheel 1.3s infinite;
    }

    @keyframes wheel {
        to {
            opacity: 0;
            top: 27px;
        }
    }

    @-webkit-keyframes wheel {
        to {
            opacity: 0;
            top: 27px;
        }
    }

    #home {
        overflow-x: hidden;
    }

    .home {
        overflow-x: hidden;
    }

    @media (max-width: 767px) {
        .hero_title {
            font-size: 3.5rem;
        }
        .sec-pad-top {
            padding-top: 6rem;
        }
    }
